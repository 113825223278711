<template>
  <div class="wrap" v-loading="loading">
    <div class="list_wrap" v-if="list.length != 0">
      <div v-for="(item, index) in list" :key="index">
        <el-popover
          placement="bottom"
          trigger="click"
          v-if="item.type == 'top_up'"
        >
          <div class="content">
            <div class="content_title">明细</div>
            <div>工作日期：{{ item.work_day | work_dayfifters }}</div>
            <div>工作时间：{{ item.work_hour }}</div>
            <div>用工人数：{{ item.jd_people }}人</div>
            <div>岗位薪资：{{ item.jd_salary }}元/小时</div>
            <div style="margin-bottom: 10px">
              时长：{{ item.work_hours }}小时
            </div>
            <div>工资：{{ item.all_jd_salary }}元</div>
            <div>服务费：{{ item.service_charge }}元</div>
            <div>总费用：{{ item.pay_charge }}元</div>
            <div>抵扣金额：{{ item.deduction_odd_salary }}元</div>
            <div>实付金额：{{ item.total_fee }}元</div>
          </div>
          <div class="list" slot="reference">
            <div class="img">
              <svg
                t="1642494917985"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="40321"
                width="30"
                height="30"
              >
                <path
                  d="M597.3504 614.4H665.6c10.24 0 17.0496-6.8096 17.0496-17.0496s-6.8096-17.0496-17.0496-17.0496h-68.2496c-10.24 0-17.0496 6.8096-17.0496 17.0496-0.0512 10.24 6.8096 17.0496 17.0496 17.0496z"
                  fill="#f59213"
                  p-id="40322"
                ></path>
                <path
                  d="M512 0C229.2224 0 0 229.2224 0 512s229.2224 512 512 512 512-229.2224 512-512S794.7776 0 512 0z m256 665.6c0 29.0304-22.1696 51.2-51.2 51.2H307.2c-29.0304 0-51.2-22.1696-51.2-51.2V443.7504h512V665.6z m0-256H256V358.4c0-29.0304 22.1696-51.2 51.2-51.2h409.6c29.0304 0 51.2 22.1696 51.2 51.2v51.2z"
                  fill="#f59213"
                  p-id="40323"
                ></path>
              </svg>
            </div>
            <div class="title">
              <span>发布需求：{{ item.jd_title }}</span
              ><span class="time">支付时间：{{ item.request_time }}</span>
            </div>
            <div class="salary">
              <span style="color: #f59213; font-weight: bold"
                >订单总费用：{{ item.pay_charge }}元</span
              >
            </div>
          </div>
        </el-popover>
        <el-popover
          placement="bottom"
          trigger="click"
          v-if="item.type == 'punch'"
        >
          <div class="content">
            <div class="content_title">明细</div>
            <div>工作开始时间：{{ item.work_start_time }}</div>
            <div style="margin-bottom: 10px">
              工作结束时间：{{ item.work_end_time }}
            </div>
            <div>上班打卡时间：{{ item.punch_start_time }}</div>
            <div style="margin-bottom: 10px">
              下班打卡时间：{{ item.punch_end_time }}
            </div>
            <div>岗位薪资：{{ item.jd_salary }}元/小时</div>
            <div>工资：{{ item.total_fee }}元</div>
          </div>
          <div class="list" slot="reference">
            <div class="img">
              <svg
                t="1642494880481"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="40083"
                width="30"
                height="30"
              >
                <path
                  d="M512 0a512 512 0 1 0 512 512A512 512 0 0 0 512 0z m286.4896 668.16a78.2336 78.2336 0 0 1-78.208 78.208H303.1808A78.2336 78.2336 0 0 1 224.9728 668.16V355.3536a78.1312 78.1312 0 0 1 78.1568-78.2336h417.1008a78.2336 78.2336 0 0 1 78.208 78.2336v91.1616h-208.5632a65.2032 65.2032 0 1 0 0 130.4064h208.6144z m-143.36-156.3904a39.1168 39.1168 0 1 1-39.1168-39.0912 39.1168 39.1168 0 0 1 39.0656 39.1936z"
                  fill="#01be4d"
                  p-id="40084"
                ></path>
              </svg>
            </div>
            <div class="title">
              <span>零工工资：{{ item.jd_title }}</span
              ><span>零工姓名：{{ item.elite_name }}</span
              ><span>支付时间：{{ item.request_time }}</span>
            </div>
            <div class="salary">
              <span style="color: #01be4d; font-weight: bold"
                >零 工 提 现：- {{ item.total_fee }}元</span
              >
            </div>
          </div>
        </el-popover>
      </div>

      <div class="pagination">
        <el-pagination
          background
          @current-change="Turn"
          layout="prev, pager, next"
          :total="cost_count"
          :page-size="15"
        >
        </el-pagination>
      </div>
    </div>
    <div class="shangxiajia" v-else>
      <span>暂无明细</span>
    </div>
  </div>
</template>

<script>
import { wallet_detail } from "@/api/me";
export default {
  filters: {
    work_dayfifters(value) {
      if (value == undefined) {
        return;
      }
      let time = value.split(",");
      let length = time.length;
      if (length > 1) {
        return (
          time[0].split("-")[0] +
          "年" +
          time[0].split("-")[1] +
          "月" +
          time[0].split("-")[2] +
          "日" +
          " - " +
          time[length - 1].split("-")[0] +
          "年" +
          time[length - 1].split("-")[1] +
          "月" +
          time[length - 1].split("-")[2] +
          "日"
        );
      } else {
        return (
          time[0].split("-")[0] +
          "年" +
          time[0].split("-")[1] +
          "月" +
          time[0].split("-")[2] +
          "日"
        );
      }
    },
  },
  data() {
    return {
      loading: false,
      // 页码
      page: 0,
      // 列表
      list: [],
      // 总数量
      cost_count: 0,
    };
  },
  methods: {
    // 翻页
    Turn(val) {
      this.page = val - 1;
      this.wallet_detail();
    },
    // 明细列表
    wallet_detail() {
      this.loading = true;
      wallet_detail({ page: this.page }).then((res) => {
        if (!res.code) {
          this.cost_count = res.data.cost_count;
          this.list = res.data.cost_list;
          this.loading = false;
        }
      });
    },
  },
  created() {
    this.wallet_detail();
  },
};
</script>

<style scoped>
.list_wrap {
  padding: 3%;
}
.list {
  display: flex;
  padding: 10px;
  border: 1px solid #eee;
  border-radius: 5px;
  margin-bottom: 1%;
  box-shadow: 1px 2px 5px #e3e0e0;
  align-items: center;
}
.title {
  width: 77%;
  display: flex;
}
.title span {
  margin-right: 40px;
}
.img {
  margin-right: 20px;
}
.content {
  line-height: 30px;
  padding: 0 20px;
}
.content_title {
  font-weight: bold;
  text-align: center;
  font-size: 16px;
  color: #000;
}
.shangxiajia {
  text-align: center;
  padding: 250px 0;
  color: #ccc;
}
.pagination {
  margin: 20px 0;
  display: flex;
  justify-content: center;
}
</style>